$(document).ready(function () {
  window._token = $('meta[name="csrf-token"]').attr('content')

  //moment.updateLocale('en', {
  //  week: {dow: 1} // Monday is the first day of the week
  //})

  $('.date').datetimepicker({
    format: 'Do MMMM YYYY',
    locale: 'en'
  })

  $('.datetime').datetimepicker({
    format: 'Do MMMM YYYY hh:mm A',
    locale: 'en',
    sideBySide: true
  })

  $('.timepicker').datetimepicker({
    format: 'hh:mm A'
  })

  $('.select-all').click(function () {
    let $select2 = $(this).parent().siblings('.select2')
    $select2.find('option').prop('selected', 'selected')
    $select2.trigger('change')
  })
  $('.deselect-all').click(function () {
    let $select2 = $(this).parent().siblings('.select2')
    $select2.find('option').prop('selected', '')
    $select2.trigger('change')
  })

  $('.select2').select2({
      placeholder: "Select...",
      allowClear: true,
  })

  $('.treeview').each(function () {
    var shouldExpand = false
    $(this).find('li').each(function () {
      if ($(this).hasClass('active')) {
        shouldExpand = true
      }
    })
    if (shouldExpand) {
      $(this).addClass('active')
    }
  })
})

// JS functions

//function to create slug
function createSlug(text){
    return '/'+text
        .toLowerCase()
        .replace(/ /g,'-')
        .replace(/[^\w-]+/g,'')
        ;
}

window.toggle_event_summary = function()
{
    if (document.getElementById('enable_event_summary').checked === true) {
        $('#event_summary_display').css('display', 'inline')
    } else {
        $('#event_summary_display').css('display', 'none');
        $('#event_summary').val('');
    }
}

window.toggle_footer = function()
{
    if (document.getElementById('enable_footer').checked === true) {
        $('#footer_display').css('display', 'inline')
    } else {
        $('#footer_display').css('display', 'none');
    }
}
